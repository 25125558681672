import { useEffect, useRef, useState } from "react";
import BuyTicket from "../components/BuyTicket/BuyTicket";
import Footer from "../components/Footer/Footer";
import Header from "../components/Header/Header";
import Location from "../components/Location/Location";
import Menu from "../components/Menu/Menu";
import Organizers from "../components/Organizers/Organizers";
import Partners from "../components/Partners/Partners";
import Schedule from "../components/Schedule/Schedule";
import "./styles.scss";
import { useLocation } from "react-router-dom";

const HomePage = () => {
  const ref = useRef<HTMLDivElement>(null);
  const location = useLocation();
  const lastHash = useRef("");
  const [show, setShow] = useState(true);
  const [position, setPosition] = useState(0);

  // useEffect(() => {
  //   if (ref && ref.current) {
  //     setPosition(ref.current.scrollTop);
  //     ref.current.addEventListener("scroll", () => {
  //       const { scrollTop } = ref.current as HTMLDivElement;
  //       setShow(position > scrollTop);
  //       setPosition(scrollTop);
  //     });
  //   }
  // }, [position]);


  // useEffect(() => {
  //   if (window.location.hash) {
  //     const id = window.location.hash.replace("#", "");
  //     const element = document.getElementById(id);
  //     if (element) {
  //       element.scrollIntoView();
  //     }
  //   }
  // }, [location]);

  return (
    <div ref={ref} className="home-page">
      <Menu show={show} />
      <Header />
      <Schedule />
      <BuyTicket />
      <Organizers />
      <Location />
      <Partners />
      <Footer />
    </div>
  );
};

export default HomePage;
